import Link, { LinkProps } from 'next/link'
import { AnchorHTMLAttributes, ComponentProps } from 'react'
import { Button } from '../LegacyButtonCompatibility'

type Props = Omit<ComponentProps<typeof Button>, 'href'> &
	Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> &
	Pick<LinkProps, 'href' | 'passHref'>

export const LinkButton = ({ variant, size, className, children, href, passHref, ...rest }: Props) => (
	<Link href={href} passHref={passHref} {...rest} style={{ textDecoration: 'unset' }}>
		<Button variant={variant} size={size} className={className}>
			{children}
		</Button>
	</Link>
)
