import { Text, View } from 'reshaped/bundle'
import { HowItWorksItem } from './HowItWorksItem'
import { NoHarmPromise } from 'experiences/shared/NoHarmPromise'
import applyIcon from './assets/apply-icon.svg'
import compareIcon from './assets/compare-icon.svg'
import signIcon from './assets/sign-icon.svg'
import relaxIcon from './assets/relax-icon.svg'
import { LinkButton } from 'ui/inputs/Button'

export const HowItWorks = () => {
	return (
		<View paddingBlock={10} align="center" gap={8}>
			<Text as="h2" variant="title-3" align="center">
				How it works
			</Text>
			<View maxWidth={144}>
				<Text align="center" variant="body-3">
					Fill out our simple form and we&apos;ll match you with offers from our network of 100+ lenders. It&apos;s
					fast, free and there&apos;s no risk to your credit.
				</Text>
			</View>
			<View direction="row" gap={{ s: 4, m: 8 }} width="100%">
				<HowItWorksItem svg={applyIcon} title="Search" subtitle="100+ providers in 60 seconds" />
				<HowItWorksItem svg={compareIcon} title="Compare" subtitle="your personalized offers" />
				<HowItWorksItem svg={signIcon} title="Apply" subtitle="with the lender of your choice" />
				<HowItWorksItem svg={relaxIcon} title="Relax" subtitle="it really is that easy" />
			</View>
			<View gap={4} align="center">
				<LinkButton href="/apply" color="positive">
					Get Started
				</LinkButton>

				<NoHarmPromise />
			</View>
		</View>
	)
}
