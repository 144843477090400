import { createHook } from 'react-sweet-state'
import { ContentfulState, ContentfulStore } from './index'
import { Resource, ResourceGroup } from 'services/ContentfulService/models/resource/resource'
import { UnexpectedContentType } from 'services/ContentfulService/errors'

const getResources = (state: ContentfulState) => state.resources || []
const getResource = (state: ContentfulState, id: string): Resource => {
	const value = state.resources.find(resource => resource.key === id)
	if (value) {
		return value
	}
	console.error('Could not find resource', id)
	return { contentType: 'resource', resource: { key: 'fake' }, key: 'fake' }
}

export const useContentfulResources = createHook(ContentfulStore, { selector: getResources })
const useContentfulResource = createHook(ContentfulStore, { selector: getResource })

export const useContentfulResourcesMapped = () => {
	const [resources] = useContentfulResources()

	return resources.reduce(
		(curr, next) => {
			let res = { [next.key]: next }

			if (next.contentType === 'resourceGroup') {
				res = next.resourceGroup.resources.reduce(
					(innerCurr, innerNext) => {
						return {
							...res,
							...innerCurr,
							[innerNext.key]: {
								contentType: 'resource',
								resource: innerNext,
								key: innerNext.key
							}
						}
					},
					{} as Record<string, Resource>
				)
			}

			return { ...curr, ...res }
		},
		{} as Record<string, Resource>
	)
}

export const usePageAsset = (key: string) => {
	const [value] = useContentfulResource(key)
	let singleValue
	switch (value.contentType) {
		case 'resource':
			singleValue = value.resource
		case 'resourceGroup':
			break
	}
	const result = singleValue?.mediaValue
	if (result) return result
	console.error('could not find asset', key)
	throw new UnexpectedContentType()
}

export const usePageTextResource = (key: string) => {
	const [value] = useContentfulResource(key)
	let singleValue
	switch (value.contentType) {
		case 'resource':
			singleValue = value.resource
			break
		case 'resourceGroup':
			break
	}
	const result = singleValue?.textValue
	if (result) return result
	throw new UnexpectedContentType()
}
export const usePageRichTextResource = (key: string) => {
	const [value] = useContentfulResource(key)
	let singleValue
	switch (value.contentType) {
		case 'resource':
			singleValue = value.resource
			break
		case 'resourceGroup':
			break
	}
	const result = singleValue?.richTextValue
	if (result) return result
	throw new UnexpectedContentType()
}

export const usePageResourceGroup = (key: string): ResourceGroup => {
	const [value] = useContentfulResource(key)
	switch (value.contentType) {
		case 'resource':
			break
		case 'resourceGroup':
			return value.resourceGroup
	}
	console.error('could not find resource group', key)
	return { name: 'fake', resources: [{ key: 'fake' }], key: 'fake' }
}
