import type { ComponentProps } from 'react'
import { Button as ReshapedButton, Text, View } from 'reshaped/bundle'

export type ButtonProps = ComponentProps<typeof ReshapedButton>

export const Button = ({ size = 'large', children, ...rest }: ButtonProps) => (
	<ReshapedButton size={size} {...rest}>
		<View as="span" paddingInline={2} justify="center">
			<Text as="span" align="center" variant="body-2">
				{children}
			</Text>
		</View>
	</ReshapedButton>
)
