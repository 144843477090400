import { CSSProperties, useContext } from 'react'
import Image, { ImageProps } from 'next/legacy/image'
import { Asset } from 'services/ContentfulService/models/asset'
import { PriorityContext } from 'experiences/shared/ContentfulImage/PriorityContext'
import { blurPlaceholderAsSVG } from 'experiences/shared/ContentfulImage/placeholder'
import { CONTENTFUL_IMAGE } from 'lib/css/magicClasses'
import { buildSizes, DEFAULT_SIZES } from 'experiences/shared/ContentfulImage/imageSizes'

const contentfulPlaceholder = (url: string) => `${url}?w=${100}&q=${20}&fm=jpg`

export interface ContentfulImageProps {
	asset: Asset
	height?: number | string
	quality?: number
	objectFit?: ImageProps['objectFit']
	objectPosition?: ImageProps['objectPosition']
	sizes?: string
	/**
	 * How the image is used, which is a proxy for how big it needs to be downloaded
	 * Responsive: think hero, it must stretch to fill the viewport
	 * Fixed: You are specifying the exact image boundaries
	 * Fill: You are specifying the minimum of the primary dimension
	 *
	 * Most of the time you're looking for Fill
	 */
	layout?: ImageProps['layout']
	width?: number
	style?: CSSProperties
	placeholder?: boolean
}

const getPlaceholderProps = (
	props: Partial<Pick<ContentfulImageProps, 'width' | 'placeholder' | 'asset'>>
): Partial<ImageProps> => {
	if (props.placeholder === false) return {}
	if (props.asset?.url === undefined) return {}
	if (props.placeholder === undefined && (props.width || 300) < 300) return {}
	const obj: Partial<ImageProps> = {
		placeholder: 'blur',
		blurDataURL:
			props.asset.placeholderDataURL ||
			blurPlaceholderAsSVG({
				src: contentfulPlaceholder(props.asset.url),
				aspectRatio: props.asset.aspectRatio
			})
	}
	return obj
}

export const ContentfulImage = ({
	asset,
	width,
	height,
	placeholder,
	layout = 'fill',
	quality = 60,
	sizes = buildSizes(DEFAULT_SIZES),
	...rest
}: ContentfulImageProps): JSX.Element | null => {
	const priority = useContext(PriorityContext)
	if (asset && asset.url) {
		const widthOrDefault = width || 500
		const heightFallback = Math.round(widthOrDefault / (asset.aspectRatio || 1))
		const safeHeight = typeof height === 'string' ? parseInt(height) : height
		if (layout === 'fill') {
			return (
				<Image
					{...getPlaceholderProps({ width, asset, placeholder })}
					priority={priority}
					src={asset.url}
					title={asset.title}
					alt={asset.title}
					className={CONTENTFUL_IMAGE}
					layout={'fill'}
					quality={quality}
					sizes={sizes}
					{...rest}
				/>
			)
		} else {
			return (
				<Image
					{...getPlaceholderProps({ width, asset, placeholder })}
					priority={priority}
					src={asset.url}
					title={asset.title}
					alt={asset.title}
					layout={layout}
					sizes={layout === 'responsive' ? sizes : undefined}
					className={CONTENTFUL_IMAGE}
					{...rest}
					quality={quality}
					width={widthOrDefault}
					height={safeHeight || heightFallback}
				/>
			)
		}
	}

	return null
}

export default ContentfulImage
