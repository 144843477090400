export const ChevronDown = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.41083 6.91083C4.73626 6.58539 5.2639 6.58539 5.58934 6.91083L10.0001 11.3216L14.4108 6.91083C14.7363 6.58539 15.2639 6.58539 15.5893 6.91083C15.9148 7.23626 15.9148 7.7639 15.5893 8.08934L10.5893 13.0893C10.2639 13.4148 9.73626 13.4148 9.41083 13.0893L4.41083 8.08934C4.08539 7.7639 4.08539 7.23626 4.41083 6.91083Z"
				fill="#323232"
			/>
		</svg>
	)
}
