import { Text, Theme } from 'reshaped/bundle'

export const NoHarmPromise = () => (
	<Theme name="fionaAccent">
		<Text as="span" variant="body-3" align="center">
			Our service is free and will
			<Text as="span" color="primary" weight="bold">
				&thinsp;NOT&thinsp;
			</Text>
			affect your credit score
		</Text>
	</Theme>
)
