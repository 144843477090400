import { useState } from 'react'
import { useContentfulResourcesMapped } from 'stores/Contentful/hooks'
import { resolveEligibleHref, useExploreEligible } from '../../shared'
import { useRouter } from 'next/router'
import { ROUTES } from 'parameters/index'
import { Button, Card, DropdownMenu, Text, View } from 'reshaped/bundle'
import { ResourceUtils } from 'services/ContentfulService/models/resource/utils'
import { NoHarmPromise } from 'experiences/shared/NoHarmPromise'
import { ChevronDown } from 'experiences/home/Hero/ProductPicker/assets/ChevronDown'
import { cnfSearchRedirectLink } from 'experiences/explore/loans/components/NewLoanSearchWidget/config'

export const options = [
	{ value: ROUTES.PERSONAL_LOANS, label: 'personal loans.', supportsExplore: true },
	{ value: ROUTES.STUDENT_LOAN_REFINANCE, label: 'student loan refinancing.' },
	{ value: ROUTES.AUTO_REFINANCE, label: 'auto refinance.' },
	{ value: ROUTES.MORTGAGE, label: 'mortgages.' },
	{ value: ROUTES.MORTGAGE_REFINANCE, label: 'mortgage refinance.' },
	{ value: ROUTES.LIFE_INSURANCE, label: 'life insurance.', supportsExplore: true },
	{ value: ROUTES.AUTO_INSURANCE, label: 'auto insurance.', external: true },
	{ value: ROUTES.CREDIT_CARDS, label: 'credit cards.', supportsExplore: true },
	{ value: ROUTES.SAVINGS, label: 'savings accounts.' }
]

export const ProductPicker = () => {
	const [selectedOption, setSelectedOption] = useState(options[0].value)
	const option = options.find(o => o.value === selectedOption)!
	const pageResources = useContentfulResourcesMapped()
	const router = useRouter()
	const eligible = useExploreEligible()

	const navigateToSection = () => {
		let href = option.value
		if (option.value === ROUTES.STUDENT_LOAN_REFINANCE || option.value === ROUTES.AUTO_REFINANCE) {
			href = cnfSearchRedirectLink
		} else if (eligible) {
			href = resolveEligibleHref(option.value)
		}

		if (option.external) {
			window.open(href, '_blank')
		} else {
			router.push(href)
		}
	}

	return (
		<Card padding={7}>
			<View gap={8} maxWidth={{ m: 117.5 }}>
				<View gap={4}>
					<Text variant="body-3">{ResourceUtils(pageResources['home.product_picker.text']).getPlainTextContent()}</Text>

					<DropdownMenu width="fit-content" position="bottom-start">
						<DropdownMenu.Trigger>
							{attributes => (
								<Button
									variant="outline"
									size="large"
									{...attributes}
									fullWidth
									endIcon={ChevronDown}
									attributes={{ style: { justifyContent: 'space-between' } }}
								>
									<Text as="span" weight="bold" variant="featured-3">
										{option.label}
									</Text>
								</Button>
							)}
						</DropdownMenu.Trigger>
						<DropdownMenu.Content>
							{options.map(o => (
								<DropdownMenu.Item key={o.value} size="large" onClick={() => setSelectedOption(o.value)}>
									<Text variant="featured-3" weight="bold">
										{o.label}
									</Text>
								</DropdownMenu.Item>
							))}
						</DropdownMenu.Content>
					</DropdownMenu>

					<View align="end">
						<Button size="large" fullWidth={{ s: true, m: false }} color="positive" onClick={navigateToSection}>
							Continue
						</Button>
					</View>
				</View>
				<NoHarmPromise />
			</View>
		</Card>
	)
}
