interface Props {
	src?: string
	aspectRatio?: number
}
const toBase64 = (str: string) =>
	typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str)

const generateSvg = (contents = '') => `
<svg width="100%" viewBox='0 0 100 100' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <radialGradient id="g" >
      <stop stop-color="#E2CDF4" offset="20%" />
       <stop stop-color="#f9f2ff" offset="75%" />
    </radialGradient>
  </defs>
  ${contents}
  <rect width="100" height="100" fill="#f9f2ff" />
  <circle id="r" r='25' cx='50' cy='30' fill="url(#g)" />
  <animate xlink:href="#r" attributeName="r" values='25;60;25'  dur="3s" repeatCount="indefinite"  />
</svg>`

const asDataURL = (source: string) => `data:image/svg+xml;base64,${toBase64(source)}`

// language=svg
const svgImage = (src: string) => `<image width="100"  xlink:href="${src}" />`

export const blurPlaceholderAsSVG = ({ src }: Props) => {
	const image = src ? svgImage(src) : ''
	const svg = generateSvg(image)
	return asDataURL(svg)
}
