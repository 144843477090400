import { TrustpilotWidget } from 'components/vendor/TrustpilotWidget'
import { Text, View } from 'reshaped/bundle'

export const Reviews = () => {
	return (
		<View gap={8} paddingBlock={{ s: 5, m: 10 }}>
			<Text as="h2" align="center" variant="title-3">
				Here&apos;s what recent users have to say about Fiona
			</Text>
			<TrustpilotWidget widget="slider" />
		</View>
	)
}
