import React, { ComponentProps } from 'react'
import { NextPage } from 'next'
import { StandardLayout } from 'shared/layout/StandardLayout'
import { getSEOServersideProps, PagePropsWrapper } from 'lib/routing/nextPageUtils'
import { getPartners } from 'services/ContentfulService/models/partner/hooks'
import { FeaturedConstraint } from 'services/ContentfulService/constraints'
import { Homepage } from 'experiences/home'

type Props = ComponentProps<typeof Homepage>

const Index: NextPage<PagePropsWrapper<Props>> = ({ metaTags, pageProps }): JSX.Element => (
	<StandardLayout metaTags={metaTags}>
		<Homepage featuredPartners={pageProps.featuredPartners} />
	</StandardLayout>
)

export const getServerSideProps = getSEOServersideProps<Props>(async () => {
	return {
		featuredPartners: await getPartners({ constraints: [FeaturedConstraint()] })
	}
})

export default Index
