import ContentfulImage from 'experiences/shared/ContentfulImage'
import { ResourceUtils } from 'services/ContentfulService/models/resource/utils'
import { useContentfulResourcesMapped } from 'stores/Contentful/hooks'
import { Text, View } from 'reshaped/bundle'
import { Container } from 'shared/layout/Container'

export const SafeSecure = () => {
	const pageResources = useContentfulResourcesMapped()

	return (
		<View backgroundColor="page-faded" padding={{ s: 5, m: 10 }}>
			<Container>
				<View gap={8} align="center">
					<View align="center">
						<ContentfulImage
							asset={ResourceUtils(pageResources['safe_secure.image']).getMediaContent()}
							layout="intrinsic"
							width={96}
							height={110}
						/>
						<Text as="h2" align="center" variant="title-3">
							{ResourceUtils(pageResources['safe_secure.title']).getPlainTextContent()}
						</Text>
					</View>
					<Text variant="body-3" align="center" color="neutral-faded">
						{ResourceUtils(pageResources['safe_secure.description']).getPlainTextContent()}
					</Text>
				</View>
			</Container>
		</View>
	)
}
