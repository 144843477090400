import { Text, View } from 'reshaped/bundle'
import Image from 'next/legacy/image'

type Props = {
	title: string
	subtitle: string
	svg: string
}

export const HowItWorksItem = ({ title, subtitle, svg }: Props) => {
	return (
		<View.Item columns={{ s: 12, m: 6, l: 3 }}>
			<View padding={4} gap={8}>
				<Image src={svg} width={205} height={160} alt={title} />
				<View gap={2}>
					<Text variant="featured-3" weight="bold" align="center">
						{title}
					</Text>
					<Text variant="body-3" align="center">
						{subtitle}
					</Text>
				</View>
			</View>
		</View.Item>
	)
}
