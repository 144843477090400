import ContentfulImage from 'experiences/shared/ContentfulImage'
import { ResourceUtils } from 'services/ContentfulService/models/resource/utils'
import { useContentfulResourcesMapped } from 'stores/Contentful/hooks'
import { ROUTES } from 'parameters/index'
import { Text, View } from 'reshaped/bundle'
import { LinkButton } from 'ui/inputs/Button'

export const CreditCards = () => {
	const pageResources = useContentfulResourcesMapped()

	return (
		<View
			direction={{ s: 'column-reverse', m: 'row' }}
			paddingBlock={{ s: 5, m: 10 }}
			align="center"
			gap={8}
			attributes={{ style: { justifyContent: 'space-between' } }}
		>
			<View.Item columns={{ s: 12, m: 6 }}>
				<View gap={4} align={{ s: 'center', m: 'start' }} maxWidth={{ s: '100%', m: 105 }}>
					<Text as="h2" variant="title-3" align={{ s: 'center', m: 'start' }}>
						{ResourceUtils(pageResources['home.section_three.title']).getPlainTextContent()}
					</Text>
					<Text variant="body-3" align={{ s: 'center', m: 'start' }}>
						{ResourceUtils(pageResources['home.section_three.text']).getPlainTextContent()}
					</Text>
					<LinkButton href={ROUTES.CREDIT_CARDS} color="positive">
						{ResourceUtils(pageResources['home.section_three.button']).getPlainTextContent()}
					</LinkButton>
				</View>
			</View.Item>

			<View.Item columns={{ s: 12, m: 6 }}>
				<View align={{ s: 'center', m: 'end' }}>
					<ContentfulImage
						asset={ResourceUtils(pageResources['home.section_three.image']).getMediaContent()}
						width={400}
						height={300}
						objectFit={'contain'}
						layout={'intrinsic'}
					/>
				</View>
			</View.Item>
		</View>
	)
}
