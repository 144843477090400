import { Partner } from 'services/ContentfulService/models/partner/partner'
import { calcMultiplier, sortPartners } from 'lib'
import ContentfulImage from 'experiences/shared/ContentfulImage'
import { ROUTES } from 'parameters/index'
import { Text, View } from 'reshaped/bundle'
import { Container } from 'shared/layout/Container'
import { Link } from '../../../ui/reshaped/Link'

type Props = { featuredPartners: Array<Partner> }

export const PartnerNetwork = ({ featuredPartners }: Props) => {
	return (
		<View backgroundColor="page-faded" paddingBlock={4}>
			<Container>
				<View direction="row" align="center" gap={8} wrap={true}>
					<View.Item columns={{ s: 12, l: 'auto' }}>
						<Text
							variant="featured-3"
							weight="bold"
							align={{ s: 'center', l: 'start' }}
							attributes={{ style: { whiteSpace: 'nowrap' } }}
						>
							Our Network Partners
						</Text>
					</View.Item>

					{featuredPartners
						.sort(sortPartners)
						.slice(0, 4)
						.map(({ id, logo }) => (
							<View.Item key={id} columns={{ s: 6, l: 'auto' }} grow={{ l: true }}>
								<View align="center">
									<ContentfulImage asset={logo} layout="fixed" width={calcMultiplier(logo, 120)} quality={40} />
								</View>
							</View.Item>
						))}

					<View.Item columns={{ s: 12, l: 'auto' }}>
						<Link href={ROUTES.PARTNERS} attributes={{ style: { textDecoration: 'none' } }}>
							<Text variant="featured-3" weight="bold" align={{ s: 'center', l: 'end' }}>
								+ more
							</Text>
						</Link>
					</View.Item>
				</View>
			</Container>
		</View>
	)
}
