import ContentfulImage from 'experiences/shared/ContentfulImage'
import { useContentfulResourcesMapped } from 'stores/Contentful/hooks'
import { ResourceUtils } from 'services/ContentfulService/models/resource/utils'
import { ROUTES } from 'parameters/index'
import { Badge, Button, Link as ReshapedLink, Text, Theme, View } from 'reshaped/bundle'
import { ExternalIcon } from '../FinancialServices/assets/ExternalIcon'
import { Container } from 'shared/layout/Container'
import Link from 'next/link'

export const CreditScore = () => {
	const pageResources = useContentfulResourcesMapped()
	return (
		<Container>
			<View
				direction={{ s: 'column-reverse', m: 'row' }}
				align="center"
				justify={{ s: 'center', m: 'start' }}
				gap={8}
				paddingBlock={{ s: 5, m: 10 }}
				attributes={{ style: { flexWrap: 'nowrap' } }}
			>
				<View gap={4} align={{ s: 'center', m: 'start' }} attributes={{ style: { flexShrink: 1 } }}>
					<Theme name="fionaAccent">
						<Badge color="primary">{ResourceUtils(pageResources['home.bottom.banner']).getPlainTextContent()}</Badge>
					</Theme>

					<Theme name="fionaBrand">
						<Text as="h2" align={{ s: 'center', m: 'start' }} variant="title-1">
							{ResourceUtils(pageResources['home.bottom.title.first']).getPlainTextContent()} <br />
							<Text color="primary">
								{ResourceUtils(pageResources['home.bottom.title.second']).getPlainTextContent()}
							</Text>
						</Text>
					</Theme>
					<Link href={ROUTES.CREDIT_SCORE_EXTERNAL} target="_blank">
						<Button endIcon={ExternalIcon} color="positive" size="large">
							Get your credit score
						</Button>
					</Link>

					<Text variant="body-3" color="neutral-faded" align={{ s: 'center', m: 'start' }}>
						Via our partner,{' '}
						<ReshapedLink
							href="https://www.transunion.com"
							attributes={{ target: '_blank', rel: 'noopener noreferrer nofollow' }}
							variant="plain"
						>
							TransUnion
						</ReshapedLink>
					</Text>
				</View>

				<View.Item grow />

				<View align="center" justify={{ s: 'center', m: 'end' }} width={{ s: '150px', m: '270px' }}>
					<ContentfulImage
						asset={ResourceUtils(pageResources['home.bottom.image']).getMediaContent()}
						layout="intrinsic"
						width={270}
					/>
				</View>
			</View>
		</Container>
	)
}
