import ContentfulImage from 'experiences/shared/ContentfulImage'
import { useContentfulResourcesMapped } from 'stores/Contentful/hooks'
import { ResourceUtils } from 'services/ContentfulService/models/resource/utils'
import { ROUTES } from 'parameters/index'
import { Card, Text, View } from 'reshaped/bundle'
import { LinkButton } from 'ui/inputs/Button'

export const LifeInsurance = () => {
	const pageResources = useContentfulResourcesMapped()

	const items = ResourceUtils(pageResources['life_insurance'])
		.getResourceGroup()
		.filter(resource => !!resource.mediaValue)
		.map(resource => resource.key)

	return (
		<View paddingBlock={{ s: 5, m: 10 }} gap={10} align="center" width="100%">
			<View gap={8}>
				<Text as="h2" align="center" variant="title-3">
					{ResourceUtils(pageResources['life_insurance.title']).getPlainTextContent()}
				</Text>
				<Text variant="body-3" align="center">
					{ResourceUtils(pageResources['life_insurance.description']).getPlainTextContent()}
				</Text>
			</View>

			<View as="div" direction="row" gap={4} justify="center" width="100%">
				{items.map((item, index) => (
					<Card key={index} attributes={{ style: { borderRadius: 0 } }}>
						<View width={42} height={30} align="center" justify="center">
							<ContentfulImage
								layout="fill"
								asset={ResourceUtils(pageResources[item]).getMediaContent()}
								width={180}
								objectFit="contain"
								sizes="200px"
							/>
						</View>
					</Card>
				))}
			</View>

			<LinkButton href={ROUTES.LIFE_INSURANCE} color="positive">
				{ResourceUtils(pageResources['life_insurance.button']).getPlainTextContent()}
			</LinkButton>
		</View>
	)
}
