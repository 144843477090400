type ImageSizeValue = `${number}vw` | `${number}px`
interface Sizes {
	max: ImageSizeValue
	min: ImageSizeValue
	desktop?: ImageSizeValue
	mobile?: ImageSizeValue
}

export const DEFAULT_SIZES: Sizes = {
	max: '700px',
	desktop: '30vw',
	min: '25vw',
	mobile: '40vw'
}

export const buildSizes = (sizes: Sizes): string => {
	const values: Array<string> = []
	values.push(`(min-width: 1280px) ${sizes.max}`)
	sizes.desktop && values.push(`(min-width: 700px) ${sizes.desktop}`)
	sizes.mobile && values.push(`(min-width: 300px) ${sizes.mobile}`)
	values.push(`${sizes.mobile}`)
	return values.join(',')
}
