import React, { PropsWithChildren } from 'react'
import { StyleAttribute } from 'reshaped/types/global'
import { Button as NewButton, ButtonProps } from './index'

interface LegacyProps {
	variant: 'primary' | 'secondary' | 'ghost'
	size: 'small' | 'base'
}

const sizeMap: Record<LegacyProps['size'], ButtonProps['size']> = {
	small: 'medium',
	base: 'large'
}

const varientMap: Record<LegacyProps['variant'], Pick<ButtonProps, 'variant' | 'color'>> = {
	ghost: { color: 'primary', variant: 'ghost' },
	primary: { color: 'positive', variant: 'solid' },
	secondary: { color: 'primary', variant: 'outline' }
}

/**
 * @deprecated This is a shim of the legacy button to support exising usage and map it to reshaped.
 * DO NOT USE THIS COMPONENT FOR NEW CODE, use the  Button component from 'ui/inputs/Button' instead
 */
export const Button = ({
	variant = 'primary',
	size = 'base',
	children,
	style,
	...rest
}: PropsWithChildren<
	Partial<LegacyProps> &
		Pick<ButtonProps, 'onClick' | 'disabled' | 'loading' | 'href' | 'className' | 'type' | 'as'> & {
			style?: StyleAttribute
		}
>) => {
	return (
		<NewButton attributes={{ style }} size={sizeMap[size]} {...varientMap[variant]} {...rest}>
			{children}
		</NewButton>
	)
}
