import { useContentfulResourcesMapped } from 'stores/Contentful/hooks'
import { Text, Theme, View } from 'reshaped/bundle'
import { ResourceUtils } from 'services/ContentfulService/models/resource/utils'
import { ProductPicker } from './ProductPicker'
import { Container } from 'shared/layout/Container'
import { hero } from './Hero.css'

export const Hero = () => {
	const pageResources = useContentfulResourcesMapped()

	return (
		<View backgroundColor="primary" paddingBlock={{ s: 5, m: 10 }} className={hero}>
			<Container>
				<View
					direction={{ s: 'column', m: 'row' }}
					align={{ m: 'center' }}
					gap={10}
					attributes={{ style: { justifyContent: 'space-between', flexWrap: 'nowrap' } }}
				>
					<Text as="h1" variant="title-1">
						{ResourceUtils(pageResources['home.hero.title.first']).getPlainTextContent()} <br />
						<Theme name="fionaBrand">
							<Text as="span" color="primary" variant="title-1">
								{ResourceUtils(pageResources['home.hero.title.second']).getPlainTextContent()}
							</Text>
						</Theme>
					</Text>
					<ProductPicker />
				</View>
			</Container>
		</View>
	)
}
