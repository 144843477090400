import { Partner } from 'services/ContentfulService/models/partner/partner'
import { Hero } from 'experiences/home/Hero'
import { SafeSecure } from 'experiences/home/SafeSecure'
import { PartnerNetwork } from 'experiences/home/PartnerNetwork'
import { FinancialServices } from 'experiences/home/FinancialServices'
import { HowItWorks } from 'experiences/home/HowItWorks'
import { Reviews } from 'experiences/home/Reviews'
import { CreditCards } from 'experiences/home/CreditCards'
import { LifeInsurance } from 'experiences/home/LifeInsurance'
import { CreditScore } from 'experiences/home/CreditScore'
import { Divider, View } from 'reshaped/bundle'
import { Container } from 'shared/layout/Container'

type Props = { featuredPartners: Array<Partner> }

export const Homepage = ({ featuredPartners }: Props) => {
	return (
		<>
			<Hero />
			<PartnerNetwork featuredPartners={featuredPartners} />
			<Container>
				<FinancialServices />
				<HowItWorks />
				<Reviews />
				<CreditCards />
				<View paddingBlock={4}>
					<Divider />
				</View>
				<LifeInsurance />
			</Container>
			<SafeSecure />
			<CreditScore />
		</>
	)
}
